import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { SessionContext } from "../context/SessionContext";
import LogIn from "./LogIn";
import Plans from "../components/plans";
import ModalComponent from "../components/ModalComponent";
import NotFound from "./NotFound";
import Box from "@mui/material/Box";

function Checkout({ supabase }) {
  const session = useContext(SessionContext);
  const navigate = useNavigate();
  const { tableId } = useParams();

  const handleClose = () => {
    navigate("/upload");
  };

  return tableId ? (
    session?.user ? (
      <Box
        sx={{
          backgroundImage: {
            lg: "linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 60%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
            xs: "linear-gradient(to bottom, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,0.7) 100%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
          },
          backgroundColor: "white",
          backgroundSize: "cover",
          backgroundPosition: "right",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100vh",
          }}
        >
          <ModalComponent
            open={true}
            handleClose={handleClose}
            dark={false}
            pricing={true}
          >
            <Plans customID={tableId} />
          </ModalComponent>
        </Box>
      </Box>
    ) : (
      <LogIn customPath={`/checkout/${tableId}`} />
    )
  ) : (
    <NotFound />
  );
}

export default Checkout;
