import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

const NewKeywordsControls = ({
  supabase,
  predictions,
  links,
  selectedPhoto,
  handleEditKeywords,
  generating,
  setGenerating,
  session,
  albumId,
  setSnackBarMessage,
  setOpenSnackBar,
}) => {
  const { t } = useTranslation();
  const [separatorFormat, setSeparatorFormat] = useState("commaSpace");

  useEffect(() => {
    const separatorFormat = localStorage.getItem("lastSeparatorFormat");
    if (separatorFormat) {
      setSeparatorFormat(separatorFormat);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("lastSeparatorFormat", separatorFormat);
  }, [separatorFormat]);

  const formatKeywords = (keywords) => {
    switch (separatorFormat) {
      case "commaSpace":
        return keywords.join(", ");
      case "commaNoSpace":
        return keywords.join(",");
      case "semicolonSpace":
        return keywords.join("; ");
      case "semicolonNoSpace":
        return keywords.join(";");
      case "hashtag":
        return keywords.map((k) => `#${k}`).join(" ");
      default:
        return keywords.join(", ");
    }
  };

  const copyKeywords = () => {
    const formattedKeywords = formatKeywords(
      predictions?.[selectedPhoto]?.data?.[1] || []
    );
    navigator.clipboard.writeText(formattedKeywords);
  };

  const handleRegenerateKeywords = async (e) => {
    e.preventDefault();
    setGenerating((prev) => [...prev, selectedPhoto]);
    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/regenerate-keywords`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            data: {
              description: predictions?.[selectedPhoto]?.data?.[0]?.[1].replace(
                " created with generative AI technology",
                ""
              ),
            },
            user_data: {
              album_id: albumId,
              sub_id:
                session?.subStatus?.[session?.subStatus?.length - 1]?.id ||
                "no_sub",
            },
          }),
        }
      );
      const json = await response.json();

      const { data, error } = json;
      if (!error) {
        handleEditKeywords(
          "e",
          selectedPhoto,
          { action: "regenerate" },
          data?.[0]?.data?.[1]?.join(", ")?.toString()
        );
        setGenerating((prev) => prev.filter((i) => i !== selectedPhoto));
      } else {
        setSnackBarMessage("⚠️ " + error);
        setOpenSnackBar(true);
      }
    } catch (error) {
      setSnackBarMessage(t("editor.serverError"));
      setOpenSnackBar(true);
    }
    setGenerating((prev) => prev.filter((i) => i !== selectedPhoto));
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "1rem",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          width: "100%",
        }}
      >
        <Select
          value={separatorFormat}
          onChange={(e) => setSeparatorFormat(e.target.value)}
          sx={{
            width: "50%",
            maxHeight: "2rem",
            fontWeight: "bold",
            fontSize: "0.7rem",
          }}
        >
          <MenuItem
            disabled
            sx={{
              fontWeight: "bold",
            }}
          >
            {t("editor.separator")}
          </MenuItem>
          <MenuItem value="commaSpace">{t("editor.comma")}</MenuItem>
          <MenuItem value="commaNoSpace">{t("editor.commaNoSpace")}</MenuItem>
          <MenuItem value="semicolonSpace">{t("editor.semicolon")}</MenuItem>
          <MenuItem value="semicolonNoSpace">
            {t("editor.semicolonNoSpace")}
          </MenuItem>
          <MenuItem value="hashtag">{t("editor.hashtag")}</MenuItem>
        </Select>
        <Button
          variant="outlined"
          color="info"
          sx={{
            width: "50%",
            fontWeight: "bold",
            fontSize: "0.7rem",
            flexGrow: 1,
          }}
          disabled={!predictions?.[selectedPhoto]?.data?.[0]?.[1]}
          startIcon={<ContentCopyIcon />}
          onClick={() => {
            copyKeywords();
          }}
        >
          {t("editor.copyAll")}
        </Button>
      </Box>
      <span style={{ display: "block", width: "100%" }}>
        <Button
          variant="outlined"
          color="secondary"
          startIcon={<AutoFixHighIcon />}
          sx={{ padding: "0.3rem 0.6rem", fontWeight: "bold" }}
          disabled={
            predictions.length < links.length ||
            generating?.includes(selectedPhoto) ||
            predictions?.[selectedPhoto]?.data?.[0]?.[1] === "N/A"
          }
          onClick={(e) => handleRegenerateKeywords(e)}
          fullWidth
        >
          {t("editor.generateMore")}
        </Button>
      </span>
    </Box>
  );
};

export default NewKeywordsControls;
