const StripeCustom = ({ userID, customID }) => {
  return (
    <stripe-pricing-table
      pricing-table-id={`prctbl_${customID}`}
      publishable-key={process.env.REACT_APP_STRIPE_PUBLIC_KEY}
      client-reference-id={userID}
    ></stripe-pricing-table>
  );
};

export { StripeCustom };
