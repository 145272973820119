import React, { useContext, Fragment } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import LandingLayout from "../Layout/LandingLayout";
import { useTranslation } from "react-i18next";

function LandingFeatures() {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  const features = [
    {
      image: "workflow",
      title: t("landing.workflow"),
      description: t("landing.workflowDescription"),
      reverse: false,
      mdReverse: false,
      styles: { pt: 5, pb: 5, gap: { xs: "2rem", md: "15rem" } },
    },
    {
      image: "settings",
      title: t("landing.settings"),
      description: t("landing.settingsDescription"),
      reverse: true,
      mdReverse: false,
      imgSize: "30rem",
      styles: { pt: 5, pb: 5, gap: { xs: "2rem", md: "15rem" } },
    },
    {
      image: "batch",
      title: t("landing.batch"),
      description: t("landing.batchDescription"),
      reverse: false,
      mdReverse: false,
      imgSize: "40rem",
      styles: { pt: 5, pb: 5, gap: { xs: "2rem", md: "15rem" } },
    },
  ];

  return (
    <Fragment>
      <Box
        sx={{
          backgroundImage: `url(/assets/landing/pattern.png)`,
          backgroundRepeat: "repeat",
          backgroundColor: "#dcdcdc",
        }}
      >
        <Typography
          variant="h3"
          fontWeight={"bold"}
          sx={{
            color: "#000",
            textAlign: "center",
            py: 5,
            borderTop: "1px solid #dcdcdc",
          }}
        >
          {t("landing.features")}
        </Typography>
        {features.map((feature, index) => (
          <LandingLayout
            styles={feature.styles}
            key={index}
            reverse={feature.reverse}
            mdReverse={feature.mdReverse}
            firstContent={
              <img
                src={`/assets/landing/${feature.image}.webp`}
                alt=""
                style={{
                  width: feature.imgSize ? feature.imgSize : "60rem",
                  pointerEvents: "none",
                  userSelect: "none",
                }}
              />
            }
            secondfContent={
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  justifyContent: "center",
                  alignItems: "flex-start",
                  maxWidth: "20rem",
                }}
              >
                <Typography
                  variant="h4"
                  color="initial"
                  fontWeight="bold"
                  textAlign={"left"}
                  sx={{ color: "#000", textAlign: "left" }}
                >
                  {feature.title}
                </Typography>

                <Typography
                  variant="body1"
                  color="initial"
                  sx={{ color: "#000", align: "left" }}
                >
                  {feature.description}
                </Typography>
              </Box>
            }
          />
        ))}
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            size="large"
            variant="contained"
            color="secondary"
            fullWidth
            sx={{
              backgroundColor: "black",
              color: "white",
              maxWidth: "20rem",
              fontWeight: "bold",
              my: "5rem",
              fontSize: "1.5rem !important",
            }}
            className="gradient-bg2"
            onClick={() => {
              if (session?.stashedUser) {
                session?.loadUserFromStash();
              } else {
                navbar.setSelectedNavItem("login");
                navbar.setOpenNavModal(true);
              }
            }}
            startIcon={!session?.user && <AutoFixHighIcon />}
          >
            {t("landing.tryNow")}
          </Button>
        </Box>
      </Box>
    </Fragment>
  );
}

export default LandingFeatures;
