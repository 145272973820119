import React from "react";
import { useTranslation } from "react-i18next";
import Continue from "./Continue";

function LogIn({ customPath = undefined }) {
  const { t } = useTranslation();

  return (
    <Continue
      primaryText={t("login.welcomeContinue")}
      buttonText={t("login.justContinue")}
      customPath={customPath}
    />
  );
}

export default LogIn;
