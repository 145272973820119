import { useState } from "react";
import {
  Button,
  Box,
  Chip,
  Typography,
  TextField,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ContentPasteGoIcon from "@mui/icons-material/ContentPasteGo";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { DraggableArea } from "react-draggable-tags";

const KeywordsEdit = ({
  selectedPhoto,
  predictions,
  handleEditKeywords,
  keywordsInput,
  setKeywordsInput,
  generating,
  albumSettings,
}) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);

  const keywordsArray = (arr) => {
    let newArr = [];
    arr.forEach((value, index) => {
      newArr.push({
        id: index + value,
        name: value,
      });
    });
    return newArr;
  };

  const stopPropagation = (e) => e.stopPropagation();

  const pasteKeywords = async () => {
    try {
      const clipboardData = await navigator.clipboard.readText();
      if (clipboardData) {
        setKeywordsInput(clipboardData.toString());
        const event = {
          target: {
            value: clipboardData.toString(),
          },
          preventDefault: () => {},
        };
        handleEditKeywords(
          event,
          selectedPhoto,
          { action: "regenerate" },
          clipboardData
        );
      }
    } catch (error) {}
  };

  const mapRange = (value, a, b, c, d) => {
    // first map value from (a..b) to (0..1)
    value = (value - a) / (b - a);
    // then map it from (0..1) to (c..d) and return it
    return c + value * (d - c);
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: "0.5rem",
        width: "100%",
        flexWrap: "wrap",
        pb: "3rem",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        <Accordion
          sx={{
            border: "1px solid hsla(0,0%,100%,.1)",
            borderRadius: "1rem",
            color: "#fff",
            backgroundColor: "transparent",
          }}
          disableGutters
          elevation={0}
          defaultExpanded={true}
          onChange={() => setExpanded((prev) => !prev)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon htmlColor="white" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="body1"
                sx={{ color: "#dcdcdc", fontWeight: "bold" }}
              >
                {t("editor.keywords")} (
                {predictions?.[selectedPhoto]?.data?.[1]?.length ?? "0"}
                {typeof albumSettings?.maxKeywords === "number"
                  ? `/${albumSettings?.maxKeywords}`
                  : ""}
                )
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.2rem",
                  mr: 1,
                }}
              >
                <Tooltip title={t("editor.paste")}>
                  <IconButton
                    variant="text"
                    color="secondary"
                    sx={{ padding: "0.7rem", fontWeight: "bold" }}
                    disabled={
                      predictions.length - 1 < selectedPhoto
                        ? true
                        : false || generating?.includes(selectedPhoto)
                    }
                    onClick={(e) => {
                      stopPropagation(e);
                      pasteKeywords();
                    }}
                  >
                    <ContentPasteGoIcon htmlColor="white" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("editor.deleteAll")}>
                  <IconButton
                    variant="text"
                    color="primary"
                    sx={{ padding: "0.7rem", fontWeight: "bold" }}
                    disabled={
                      predictions.length - 1 < selectedPhoto
                        ? true
                        : false || generating?.includes(selectedPhoto)
                    }
                    onClick={(e) => {
                      stopPropagation(e);
                      handleEditKeywords(
                        predictions?.[selectedPhoto]?.data?.[1],
                        selectedPhoto,
                        { action: "delete" }
                      );
                    }}
                  >
                    <DeleteIcon htmlColor="white" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Box
              sx={{
                width: "100%",
                mb: 2,
              }}
            >
              <form
                onSubmit={(e) =>
                  handleEditKeywords(e, selectedPhoto, { action: "add" })
                }
                style={{
                  display: "flex",
                  width: "100%",
                  height: "2.5rem",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{
                    fontSize: "2rem",
                    padding: 0,
                    borderRadius: "1.5rem 0 0 1.5rem",
                  }}
                  disabled={
                    predictions.length - 1 < selectedPhoto
                      ? true
                      : false || generating?.includes(selectedPhoto)
                  }
                >
                  +
                </Button>

                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  placeholder={t("editor.commaKeywords")}
                  InputProps={{
                    style: {
                      color: "#dcdcdc",
                      fontSize: "0.8rem",
                      backgroundColor: "transparent",
                      border: "none",
                      padding: 0,
                      borderRadius: "0 1.5rem 1.5rem 0",
                      height: "100%",
                      fontWeight: "bold",
                    },
                  }}
                  value={keywordsInput}
                  sx={{
                    flexGrow: 1,
                    fieldset: {
                      borderColor: "hsla(0,0%,100%,.1)",
                      transition: "all .2s ease-out",
                      "&:hover": {
                        borderColor: "red !important",
                      },
                    },
                  }}
                  onChange={(e) => setKeywordsInput(e.target.value)}
                  disabled={
                    predictions.length - 1 < selectedPhoto
                      ? true
                      : false || generating?.includes(selectedPhoto)
                  }
                />
              </form>
            </Box>

            {expanded && (
              <DraggableArea
                tags={
                  predictions?.[selectedPhoto]?.data?.[1] &&
                  !generating.includes(selectedPhoto)
                    ? typeof albumSettings?.maxKeywords === "number"
                      ? keywordsArray(
                          predictions?.[selectedPhoto]?.data?.[1].slice(
                            0,
                            albumSettings?.maxKeywords
                          )
                        )
                      : keywordsArray(predictions?.[selectedPhoto]?.data?.[1])
                    : keywordsArray([])
                }
                render={({ tag, index }) => (
                  <Chip
                    label={tag.name}
                    size="small"
                    key={tag}
                    style={{
                      margin: "0.2rem",
                      backgroundColor: `rgba(255,58,92,${mapRange(
                        index,
                        0,
                        predictions?.[selectedPhoto]?.data?.[1]?.length,
                        0.4,
                        0.05
                      )}`,
                      color: "#dcdcdc",
                      cursor: "move",
                      fontWeight: "bold",
                    }}
                    sx={{
                      padding: ".2rem",
                      outline: "0px solid rgba(255,58,92,.5)",
                      transition: "all 80ms ease-out",
                      "&:hover": {
                        color: "white !important",
                        outline: "5px solid rgba(255,58,92,.7)",
                      },
                    }}
                    onDelete={() =>
                      handleEditKeywords([tag.name], selectedPhoto, {
                        action: "delete",
                      })
                    }
                    disabled={
                      predictions.length - 1 < selectedPhoto
                        ? true
                        : false || generating?.includes(selectedPhoto)
                    }
                  />
                )}
                onChange={(tags) =>
                  handleEditKeywords(tags, selectedPhoto, { action: "order" })
                }
              />
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default KeywordsEdit;
