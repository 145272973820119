import React, { Fragment } from "react";
import LandingTool from "../components/LandingTool";
import ToolPage from "./ToolPage";

function ToolMetadata() {
  return (
    <Fragment>
      <ToolPage>
        <LandingTool textPrefix="metadataTool" />
      </ToolPage>
    </Fragment>
  );
}

export default ToolMetadata;
