import { Fragment, useState } from "react";
import { Box, TextField, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Pagination from "@mui/material/Pagination";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";
import { DoubleArrow } from "@mui/icons-material";

const AlbumsFilter = ({
  count,
  currentPage,
  handlePageChange,
  setSearchTerm,
  searchTerm,
  handleChange,
  selectedAlbums,
  setSelectedAlbums,
  setSelectAll,
  selectedActions,
  showAlbumId,
  setShowAlbumId,
  loadAllPages,
}) => {
  const { t } = useTranslation();
  const [allPagesLoaded, setAllPagesLoaded] = useState(false);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        overflowX: "auto",
      }}
    >
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        value={searchTerm}
        variant="outlined"
        placeholder={t("albums.search")}
        InputProps={{
          style: {
            color: "#dcdcdc",
            fontSize: "1rem",
            backgroundColor: "transparent",
            border: "none",
            padding: 0,
            borderRadius: "1.5rem",
            height: "100%",
            fontWeight: "bold",
          },
          startAdornment: (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <IconButton
                aria-label="clear"
                onClick={() => setSearchTerm("")}
                sx={{
                  position: "absolute",
                  aspectRatio: "1/1",
                  visibility: !searchTerm.length ? "hidden" : "visible",
                }}
              >
                <ClearIcon htmlColor="gray" sx={{ mx: 2 }} />
              </IconButton>
              <SearchIcon
                htmlColor="gray"
                sx={{
                  mx: 2,
                  visibility: searchTerm.length ? "hidden" : "visible",
                }}
              />
            </Box>
          ),
        }}
        sx={{
          flexGrow: 1,
          fieldset: {
            borderColor: "rgba(255, 255, 255, 0.12)",
            transition: "all .2s ease-out",
          },
          width: "100%",
        }}
        onChange={(e) => {
          handleChange(e);
        }}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          my: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <input
            type="checkbox"
            checked={showAlbumId}
            onChange={() => setShowAlbumId((prev) => !prev)}
          />
          <Typography
            variant="body1"
            sx={{ color: "#dcdcdc", fontWeight: "bold" }}
          >
            {t("albums.displayAlbumId")}
          </Typography>

          <Fragment>
            <IconButton
              aria-label=""
              onClick={() => {
                if (selectedAlbums?.length) {
                  setSelectedAlbums([]);
                  setSelectAll(false);
                }
              }}
              disabled={!selectedAlbums?.length}
            >
              <ClearIcon />
            </IconButton>
            <Typography
              variant="body1"
              sx={{
                color: selectedAlbums?.length ? "#dcdcdc" : "#a0a0a0",
                fontWeight: "bold",
              }}
            >
              {t("albums.selected")}{" "}
              <span
                style={{
                  color: selectedAlbums?.length ? "deepskyblue" : "#a0a0a0",
                }}
              >
                {selectedAlbums?.length}
              </span>
            </Typography>

            {selectedActions
              ?.filter((action) => action?.multiple === true)
              ?.map((action, index) => (
                <IconButton
                  aria-label=""
                  onClick={() => {
                    if (selectedAlbums?.length) {
                      action?.action(selectedAlbums);
                      setSelectedAlbums([]);
                      setSelectAll(false);
                    }
                  }}
                  key={index}
                  disabled={!selectedAlbums?.length}
                >
                  <action.icon />
                </IconButton>
              ))}
          </Fragment>
        </Box>

        <Box
          sx={{
            display: "flex",
            minWidth: "10rem",
          }}
        >
          <Pagination
            count={count}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              mt: 0.3,
            }}
          />

          {!allPagesLoaded && (
            <IconButton
              aria-label={t("albums.loadMore")}
              title={t("albums.loadMore")}
              onClick={() => {
                loadAllPages();
                setAllPagesLoaded(true);
              }}
              disabled={currentPage === count}
            >
              <DoubleArrow fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default AlbumsFilter;
