import React, { Fragment } from "react";
import LandingUse from "../components/LandingUse";
import LandingStats from "../components/LandingStats";
import LandingPricing from "../components/LandingPricing";
import LandingFeatures from "../components/LandingFeatures";
import LandingFaqs from "../components/LandingFaqs";
import LandingFooter from "../components/LandingFooter";
import LandingVideo from "../components/LandingVideo";
import LandingTestimonials from "../components/LandingTestimonials";
import LanguageSelector from "../components/LanguageSelector";

function ToolPage({ children }) {
  return (
    <Fragment>
      <LanguageSelector />
      {children}
      <LandingTestimonials />
      <LandingVideo />
      <LandingStats />
      <LandingUse />
      <LandingFeatures />
      <LandingPricing />
      <LandingFaqs />
      <LandingFooter />
    </Fragment>
  );
}

export default ToolPage;
