import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import { useContext } from "react";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import IframeResizer from "iframe-resizer-react";

function LandingTestimonials() {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        overflowX: "hidden",
      }}
    >
      <Typography
        variant="h3"
        fontWeight={"bold"}
        sx={{
          color: "#000",
          textAlign: "center",
          py: 5,
          borderTop: "1px solid #dcdcdc",
        }}
      >
        ❤️ {t("landing.testimonial")}
      </Typography>
      <Box
        sx={{
          width: "70%",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <IframeResizer
          src="https://embed-v2.testimonial.to/w/phototag-ai?animated=on&theme=light&shadowColor=ffffff&speed=1&hideDate=on&tag=all"
          style={{ width: "1px", minWidth: "100%", border: "none" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          sx={{
            backgroundColor: "black",
            color: "white",
            maxWidth: "20rem",
            fontWeight: "bold",
            my: "5rem",
            fontSize: "1.5rem !important",
          }}
          className="gradient-bg2"
          onClick={() => {
            if (session?.stashedUser) {
              session?.loadUserFromStash();
            } else {
              navbar.setSelectedNavItem("login");
              navbar.setOpenNavModal(true);
            }
          }}
          startIcon={!session?.user && <AutoFixHighIcon />}
        >
          {t("landing.tryNow")}
        </Button>
      </Box>
    </Box>
  );
}

export default LandingTestimonials;
