import React, { Fragment, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import AppleIcon from "@mui/icons-material/Apple";
import MicrosoftIcon from "@mui/icons-material/Microsoft";
import DownloadIcon from "@mui/icons-material/Download";

function ConfigPlugIn({ supabase }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [latestVersion, setLatestVersion] = useState("1.0.0");

  const downloadPlugin = () => {
    const url = `https://github.com/joincodeconda/phototagai.lrplugin/releases/download/${latestVersion}/phototagai.lrplugin.zip`;
    const element = document.createElement("a");
    element.href = url;
    element.setAttribute("download", "phototagai.lrplugin.zip");
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  useEffect(() => {
    fetch(
      "https://api.github.com/repos/joincodeconda/phototagai.lrplugin/releases/latest"
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.tag_name) {
          setLatestVersion(res.tag_name);
        }
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>PhotoTag.ai - {t("pluginHelmet.headingSubtext")}</title>
        <meta
          name="description"
          content={t("pluginHelmet.headingDescription")}
        />
        <meta
          property="og:title"
          content={"PhotoTag.ai - " + t("pluginHelmet.headingSubtext")}
        />
        <meta
          property="og:description"
          content={t("pluginHelmet.headingDescription")}
        />
        <meta
          property="og:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:site_name" content="PhotoTag.ai" />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:title"
          content={"PhotoTag.ai - " + t("pluginHelmet.headingSubtext")}
        />
        <meta
          name="twitter:description"
          content={t("pluginHelmet.headingDescription")}
        />
        <meta
          name="twitter:image"
          content="https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebPage",
              "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": "${window.location.href}"
              },
              "headline": "PhotoTag.ai - ${t("pluginHelmet.headingSubtext")}",
              "description": "${t("pluginHelmet.headingDescription")}",
              "image": "https://login.phototag.ai/storage/v1/object/public/assets/thumbnail.jpg",
              "publisher": {
                "@type": "Organization",
                "name": "PhotoTag.ai",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://phototag.ai/assets/logo.png"
                }
              }
            }
          `}
        </script>
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <Box
        sx={{
          backgroundColor: "white",
          backgroundSize: "cover",
          backgroundPosition: "right",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          paddingTop: "1rem",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            justifyContent: "center",
            alignItems: "center",
            maxWidth: { xs: "90vw", md: "50vw" },
            mt: "8rem",
            mb: "8rem",
          }}
        >
          <Typography
            variant="h4"
            component="h1"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            {t("plugin.header")}
          </Typography>
          <Typography paragraph textAlign="center">
            {t("plugin.subtext")}
          </Typography>

          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              downloadPlugin();
            }}
            startIcon={
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <AppleIcon />
                <MicrosoftIcon />
              </Box>
            }
            endIcon={<DownloadIcon />}
          >
            {t("plugin.download") + " (v" + latestVersion + ")"}
          </Button>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              marginTop: "2rem",
              padding: "2rem",
              backgroundColor: "#f5f5f5",
              borderRadius: "8px",
              maxWidth: "100%",
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {t("plugin.documentation")}
            </Typography>
            <Typography paragraph>{t("plugin.docSubtext")}</Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("plugin.installation")}
            </Typography>
            <Typography paragraph>
              <ol>
                <li
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    downloadPlugin();
                  }}
                >
                  {t("plugin.step1")}
                </li>
                <li>{t("plugin.step2")}</li>
                <li>{t("plugin.step3")}</li>

                <li
                  style={{
                    color: "blue",
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    navigate("/api");
                  }}
                >
                  {t("plugin.step4")}
                </li>
                <li>{t("plugin.step5")}</li>
                <li>{t("plugin.step6")}</li>
              </ol>
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {t("plugin.usage")}
            </Typography>
            <Typography paragraph>
              <ol>
                <li>{t("plugin.step7")}</li>
                <li>{t("plugin.step8")}</li>
                <li>{t("plugin.step9")}</li>
                <li>{t("plugin.step10")}</li>
                <li>{t("plugin.step11")}</li>
                <li>{t("plugin.step12")}</li>
              </ol>
            </Typography>
            <Typography paragraph sx={{ fontWeight: "bold" }}>
              {t("plugin.reminder")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
}

export default ConfigPlugIn;
