import { useContext, Fragment, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import CheckIcon from "@mui/icons-material/Check";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import { useTranslation } from "react-i18next";
import { CircularProgress, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

export default function BundleItem({
  title,
  credits_count,
  subheader,
  price,
  old_price,
  price_id,
  coupon_id,
  description,
  buttonText,
  highlighted,
  action,
}) {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  const [loading, setLoading] = useState(false);
  const [multiple, setMultiple] = useState(1);
  const [adjustedPrice, setAdjustedPrice] = useState(price);
  const [adjustedOldPrice, setAdjustedOldPrice] = useState(old_price);
  const [adjustedCreditsCount, setAdjustedCreditsCount] =
    useState(credits_count);

  const handleClick = () => {
    if (session?.user?.id || session?.stashedUser?.id) {
      setLoading(true);
      session?.handlePayment(price_id, credits_count, multiple, coupon_id);
    } else {
      localStorage.setItem(
        "goToCheckout",
        JSON.stringify({ price_id, credits_count, multiple, coupon_id })
      );
      navbar.setSelectedNavItem(action);
      navbar.setOpenNavModal(true);
    }
  };

  const handleMultipleChange = (delta) => {
    setMultiple((prev) => Math.min(99, Math.max(1, prev + delta)));
  };

  useEffect(() => {
    setAdjustedPrice(price * multiple);
    setAdjustedOldPrice(old_price ? old_price * multiple : null);
    setAdjustedCreditsCount(credits_count * multiple);
  }, [multiple, price, old_price, credits_count]);

  return (
    <Fragment>
      <CssBaseline />

      <Grid item key={title} xs={12} md={4}>
        <Box
          sx={{
            bgcolor: "white",
            width: "100%",
            outline: `3px solid ${highlighted ? "#3535ff" : "lightgray"}`,
            transition: "all 150ms ease-in-out",
            borderRadius: "1rem",
            "&:hover": {
              outline: `${highlighted ? "6px" : "3px"} solid ${
                highlighted ? "#3535ff" : "lightgray"
              }`,
              scale: "1.01",
              cursor: "pointer",
            },
          }}
        >
          <Box
            sx={{
              p: "2rem",
            }}
          >
            <Chip
              label={subheader}
              sx={{
                bgcolor: "#dcdcff",
                color: "#3535ff",
                fontWeight: "bold",
                borderRadius: "0.7rem",
                fontSize: "1rem",
                mb: 2,
              }}
            />
            <Typography
              component="h6"
              variant="h6"
              align="left"
              fontWeight={"bold"}
              sx={{
                color: "black",
                mb: 1,
              }}
            >
              {title}
              {multiple !== 1 ? ` (×${multiple})` : ""}
            </Typography>
            <Box>
              <Typography
                component="h3"
                variant="h3"
                align="center"
                fontWeight={"bold"}
                sx={{
                  color: "black",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  gap: "0.5rem",
                }}
              >
                {adjustedOldPrice && (
                  <Typography
                    component="span"
                    variant="h5"
                    fontWeight={"bold"}
                    sx={{
                      color: "gray",
                      textDecoration: "line-through",
                      fontSize: "1.5rem",
                    }}
                  >
                    {session?.currentCurrency === "EUR" ? "€" : "$"}
                    {Math.round(adjustedOldPrice).toLocaleString()}
                  </Typography>
                )}
                {session?.currentCurrency === "EUR" ? "€" : "$"}
                {Math.round(adjustedPrice).toLocaleString()}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                mt: 2,
              }}
            >
              <Typography
                component="p"
                variant="p"
                align="left"
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "1.1rem",
                }}
              >
                {adjustedCreditsCount.toLocaleString()}{" "}
                {t("landing.photoCredits")}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              bgcolor: highlighted ? "#dcdcff" : "WhiteSmoke",
              minHeight: "5rem",
              borderRadius: "0 0 0.8rem 0.8rem",
              px: 4,
              py: 3,
            }}
          >
            {description.map((feature, index) => (
              <Box
                sx={{
                  display: "flex",
                  gap: "1rem",
                }}
                key={index}
              >
                <CheckIcon color="success" />
                <Typography
                  component="p"
                  variant="body2"
                  align="left"
                  sx={{
                    color: "black",
                  }}
                >
                  {feature}
                </Typography>
              </Box>
            ))}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.2rem",
              }}
            >
              <IconButton
                sx={{
                  color: "white",
                  bgcolor: "#3535ff",
                  width: "20px",
                  height: "20px",
                  "&:hover": {
                    bgcolor: "#4d4dff",
                  },
                }}
                onClick={() => handleMultipleChange(-1)}
              >
                <RemoveIcon />
              </IconButton>
              <Typography
                component="span"
                sx={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                  color: "black",
                  padding: "0.5rem",
                }}
              >
                {multiple}
              </Typography>
              <IconButton
                sx={{
                  color: "white",
                  bgcolor: "#3535ff",
                  width: "20px",
                  height: "20px",
                  "&:hover": {
                    bgcolor: "#4d4dff",
                  },
                }}
                onClick={() => handleMultipleChange(1)}
              >
                <AddIcon />
              </IconButton>
              <Button
                variant="contained"
                color="info"
                size="large"
                sx={{
                  bgcolor: "#3535ff",
                  color: "white",
                  borderRadius: "1rem",
                  fontWeight: "bold",
                  flex: 1,
                  "&:hover": {
                    bgcolor: "#4d4dff",
                  },
                  ml: 2,
                }}
                onClick={handleClick}
              >
                {!loading ? buttonText : <CircularProgress size={20} />}
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Fragment>
  );
}
