import React, { useState, useContext } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { NavbarContext } from "../context/NavbarContext";
import { SessionContext } from "../context/SessionContext";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import LandingLayout from "../Layout/LandingLayout";
import { useTranslation } from "react-i18next";

function LandingTop() {
  const { t } = useTranslation();
  const navbar = useContext(NavbarContext);
  const session = useContext(SessionContext);

  const examples = [
    { video: "woman", source: "https://unsplash.com/photos/d5kryXlP1y0" },
    { video: "car", source: "https://unsplash.com/photos/sNjBGsaB9rc" },
    { video: "cat", source: "https://unsplash.com/photos/mrTydVjg04o" },
    { video: "cake", source: "https://unsplash.com/photos/Mzy-OjtCI70" },
  ];
  const [currentExample, setCurrentExample] = useState(0);

  const getVideo = () => {
    if (currentExample !== examples.length - 1) {
      setCurrentExample((prev) => prev + 1);
    } else {
      setCurrentExample(0);
    }
  };

  return (
    <LandingLayout
      mdReverse
      styles={{
        backgroundImage: {
          lg: "linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 60%), url(https://images.unsplash.com/photo-1491895200222-0fc4a4c35e18?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80)",
          xs: "linear-gradient(to bottom, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,0.7) 100%), url(https://images.unsplash.com/photo-1491895200222-0fc4a4c35e18?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1548&q=80)",
        },
        backgroundColor: "white",
        backgroundSize: "cover",
        backgroundPosition: "right",
        pt: { xs: "5rem", md: "8rem" },
        pb: "1rem",
        gap: "2rem",
      }}
      firstContent={
        <Box
          sx={{
            width: "100%",
            maxWidth: { xs: "100%", md: "25rem", lg: "35rem" },
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "center",
              alignItems: { xs: "center", md: "flex-start" },
            }}
          >
            <Typography
              variant="h3"
              color="initial"
              fontWeight="bold"
              sx={{
                textAlign: { xs: "center", md: "left" },
                color: "#000",
                width: { xs: "100%", md: "90%" },
              }}
            >
              {t("landing.landingHeader_2")}
            </Typography>

            <Typography
              variant="body1"
              color="initial"
              sx={{
                textAlign: { xs: "center", md: "left" },
                color: "#000",
                width: { xs: "100%", md: "80%" },
              }}
            >
              <strong>{t("landing.landingSubheader1_2")}</strong>{" "}
              {t("landing.landingSubheader2_2")}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignitems: "flex-start",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <CheckRoundedIcon color="success" />
              <Typography
                variant="body2"
                color="initial"
                sx={{ align: "left", color: "rgba(0,0,0,0.6)" }}
              >
                {t("landing.landingBullet1")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <CheckRoundedIcon color="success" />
              <Typography
                variant="body2"
                color="initial"
                sx={{ align: "left", color: "rgba(0,0,0,0.6)" }}
              >
                {t("landing.landingBullet4")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <CheckRoundedIcon color="success" />
              <Typography
                variant="body2"
                color="initial"
                sx={{ align: "left", color: "rgba(0,0,0,0.6)" }}
              >
                {t("landing.landingBullet3")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <CheckRoundedIcon color="success" />
              <Typography
                variant="body2"
                color="initial"
                sx={{ align: "left", color: "rgba(0,0,0,0.6)" }}
              >
                {t("landing.landingBullet5")}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "0.5rem" }}>
              <CheckRoundedIcon color="success" />
              <Typography
                variant="body2"
                color="initial"
                sx={{ align: "left", color: "rgba(0,0,0,0.6)" }}
              >
                {t("landing.landingBullet2")}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
              width: { xs: "100%", lg: "75%" },
              userSelect: "none",
              zIndex: "1",
              mt: -4,
              opacity: "0.2",
              "&:hover": {
                opacity: "0.5",
                cursor: "pointer",
              },
            }}
          >
            <img
              src="/assets/compatible.webp"
              alt=""
              style={{
                width: "100%",
              }}
            />
          </Box>

          <Box>
            <Box sx={{ position: "relative", width: "100%" }}>
              <Box
                sx={{
                  position: "absolute",
                  width: { xs: "8rem", sm: "10rem" },
                  right: { xs: "-3rem", sm: "-8rem" },
                  bottom: 0,
                  userSelect: "none",
                  pointerEvents: "none",
                  zIndex: "1",
                  mixBlendMode: "difference",
                }}
              >
                <img
                  src="/assets/cta-home.png"
                  alt=""
                  style={{
                    width: "100%",
                    display: "none",
                  }}
                />
              </Box>
              <Button
                size="large"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  fontWeight: "bold",
                  fontSize: "1.5rem !important",
                }}
                className="gradient-bg2"
                onClick={() => {
                  if (session?.stashedUser) {
                    session?.loadUserFromStash();
                  } else {
                    navbar.setSelectedNavItem("login");
                    navbar.setOpenNavModal(true);
                  }
                }}
                startIcon={<AutoFixHighIcon />}
              >
                {t("landing.tryFree")}
              </Button>
            </Box>
          </Box>
        </Box>
      }
      secondfContent={
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexDirection: "column",
            gap: "1rem",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <video
            src={`/assets/${examples[currentExample].video}.webm`}
            poster={"/assets/poster.png"}
            preload="auto"
            muted
            autoPlay
            controls={false}
            onEnded={() => getVideo()}
          />
          {examples[currentExample].source && (
            <Link href={examples[currentExample].source} target="_blank">
              <Typography
                variant="body2"
                color="primary"
                sx={{ color: "gray", textDecorationColor: "gray !important" }}
              >
                {t("landing.sourcePhoto")}
              </Typography>
            </Link>
          )}
          <Box
            sx={{
              backgroundColor: "rgba(0,0,50,0.2)",
              width: "45%",
              height: "1.5rem",
              filter: "blur(20px)",
            }}
          />
        </Box>
      }
    />
  );
}

export default LandingTop;
