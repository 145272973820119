import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const TopControls = ({ selectedPhoto, links, handleSelect }) => {
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
        mt: 3,
      }}
    >
      <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
        <Typography variant="body1" sx={{ color: "#414145" }}>
          {selectedPhoto + 1}/{links.length}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "1rem",
        }}
      >
        <IconButton
          aria-label="NavigateBeforeIcon"
          onClick={() =>
            handleSelect(selectedPhoto === 0 ? 0 : selectedPhoto - 1)
          }
          sx={{
            color: "#f11d64",
          }}
          disabled={selectedPhoto === 0}
        >
          <NavigateBeforeIcon />
        </IconButton>

        <IconButton
          aria-label=""
          onClick={() =>
            handleSelect(
              selectedPhoto === links.length - 1
                ? links.length - 1
                : selectedPhoto + 1
            )
          }
          sx={{
            color: "#f11d64",
          }}
          disabled={selectedPhoto === links.length - 1}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TopControls;
