import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { SessionContext } from "../context/SessionContext";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import AutoRefillMenu from "./AutoRefillMenu";

const AutoRefill = ({ supabase, handleClose }) => {
  const { t } = useTranslation();
  const session = useContext(SessionContext);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        variant="h4"
        color="initial"
        fontWeight="bold"
        align="center"
        sx={{
          padding: { xs: "0 2rem" },
        }}
      >
        {t("plan.autoRefill")}
      </Typography>
      <Typography
        variant="p"
        color="initial"
        align="center"
        sx={{
          padding: { xs: "0 2rem" },
          marginTop: "1rem",
        }}
      >
        {t("plan.autoRefillSchedule")}
      </Typography>
      <Box
        sx={{
          width: { xs: "90%", md: "100%" },
          minWidth: { xs: "15rem", md: "40rem" },
        }}
      >
        {session?.user?.id ? (
          <AutoRefillMenu supabase={supabase} handleClose={handleClose} />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "20rem",
              marginTop: "5rem",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AutoRefill;
