import React, { useContext, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import { CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Continue({
  primaryText,
  buttonText,
  timeout = 2000,
  customPath = undefined,
}) {
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, timeout);
    return () => clearTimeout(timer);
  }, [timeout]);

  return (
    <Box
      sx={{
        backgroundImage: {
          lg: "linear-gradient(to right, rgba(255,255,255,1) 30%, rgba(255,255,255,0.7) 60%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
          xs: "linear-gradient(to bottom, rgba(255, 255, 255,1) 0%, rgba(255, 255, 255,0.7) 100%), url(https://images.unsplash.com/photo-1674673353760-c86fdc093a56?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80)",
        },
        backgroundColor: "white",
        backgroundSize: "cover",
        backgroundPosition: "right",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
          height: "100vh",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: { xs: "90vw", md: "200vw" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "1.5rem",
            paddingX: "7rem",
            paddingY: "4rem",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "12px",
            textAlign: "center",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography
                variant="h4"
                component="h1"
                align="center"
                sx={{ fontWeight: "bold", color: "#333", mb: "1rem" }}
              >
                {primaryText}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{
                  padding: "16px 32px",
                  fontSize: "1.2rem",
                  fontWeight: "bold",
                  backgroundColor: "#1976d2",
                  "&:hover": {
                    backgroundColor: "#1565c0",
                  },
                }}
                onClick={() => {
                  if (session?.user) {
                    navigate("/upload");
                  } else if (session?.stashedUser) {
                    session?.loadUserFromStash(customPath);
                  } else {
                    navbar?.setIsVisible(true);
                    navbar?.setSelectedNavItem("login");
                    navbar?.setOpenNavModal(true);
                  }
                }}
              >
                {buttonText}
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Continue;
