import { createContext, useState, useEffect } from "react";
import { supabase } from "../lib/helper/supabaseClient";
import { useNavigate } from "react-router-dom";
import { blockedTerms } from "../blockedTerms";

const countryCodesEU = [
  "de",
  "fr",
  "it",
  "es",
  "pt",
  "nl",
  "pl",
  "at",
  "be",
  "cy",
  "ee",
  "fi",
  "gr",
  "ie",
  "lv",
  "lt",
  "lu",
  "mt",
  "sk",
  "si",
  "sm",
  "hr",
  "va",
  "mc",
  "ad",
  "li",
  "ch",
  "no",
  "se",
  "dk",
  "is",
  "gb",
  "uk",
  "bg",
  "cz",
  "hu",
  "ro",
  "rs",
  "me",
  "al",
  "ba",
  "mk",
  "md",
  "ua",
  "by",
  "ru",
];

export const SessionContext = createContext({});

const SessionContextProvider = ({ children }) => {
  const navigate = useNavigate();

  const [user, setUser] = useState(null);
  const [stashedUser, setStashedUser] = useState(null);
  const [subStatus, setSubStatus] = useState(null);
  const [planCreditsUsed, setPlanCreditsUsed] = useState(undefined);
  const [bundleCreditsUsed, setBundleCreditsUsed] = useState(undefined);
  const [bundleCreditsAllowed, setBundleCreditsAllowed] = useState(undefined);
  const [bundleCreditsAllowedTotal, setBundleCreditsAllowedTotal] =
    useState(undefined);
  const [showPopup, setShowPopup] = useState(false);
  const [planEmpty, setPlanEmpty] = useState(false);
  const [subChanged, setSubChanged] = useState(false);
  const [profitWellStarted, setProfitWellStarted] = useState(false);
  const [checkStorageFromTrends, setCheckStorageFromTrends] = useState(false);
  const [openWindow, setOpenWindow] = useState(null);
  const [currentCurrency, setCurrentCurrency] = useState("USD");
  const [maxBatchSize, setMaxBatchSize] = useState(500);
  const [maxImageSize, setMaxImageSize] = useState(40);
  const [maxVideoSize, setMaxVideoSize] = useState(500);
  const [enableExport, setEnableExport] = useState(false);

  useEffect(() => {
    if (
      subStatus?.[subStatus?.length - 1]?.active ||
      bundleCreditsAllowedTotal > 10
    ) {
      setEnableExport(true);
    }
  }, [subStatus, bundleCreditsAllowedTotal]);

  useEffect(() => {
    if (bundleCreditsAllowed >= 100000) {
      setMaxBatchSize(1500);
      setMaxImageSize(60);
      setMaxVideoSize(2000);
    } else if (bundleCreditsAllowed >= 10000) {
      setMaxBatchSize(1000);
      setMaxImageSize(50);
      setMaxVideoSize(1000);
    }
  }, [bundleCreditsAllowed]);

  const checkUser = async (customPath = undefined) => {
    const currSession = await supabase?.auth?.getSession();
    const currUser = currSession?.data?.session?.user;

    if (sessionStorage.getItem("active")) {
      if (currUser) {
        if (stashedUser) {
          navigate(customPath || "/upload", { state: { closeDrawer: true } });
        }
        const { data: subscriptionData, error } = await supabase
          .from("subscriptions")
          .select("*")
          .match({ user_id: currUser?.id, active: true })
          .order("created_at", { ascending: true });

        if (!error) {
          if (
            subscriptionData?.length > 0 &&
            subscriptionData[subscriptionData.length - 1].active &&
            new Date(
              subscriptionData[subscriptionData.length - 1].period_end
            ).getTime() > Date.now()
          ) {
            let subDataCopy = [...subscriptionData];
            subDataCopy[subDataCopy?.length - 1].plan.metadata.size = Math.max(
              subDataCopy[subDataCopy?.length - 1].plan.metadata.size || 0,
              40
            );
            subDataCopy[subDataCopy?.length - 1].plan.metadata.vsize = Math.max(
              subDataCopy[subDataCopy?.length - 1].plan.metadata.vsize || 0,
              500
            );
            setSubStatus(subDataCopy);
          }
        }
      }
      setStashedUser(null);
      setUser(currUser ?? null);
    } else {
      setStashedUser(currUser ?? null);
      setUser(null);
    }
  };

  const login = async (providerName) => {
    sessionStorage.setItem("active", true);
    supabase.auth.signInWithOAuth({
      provider: providerName,
    });
  };

  const loginWithEmail = async (email, password, captchaToken) => {
    const { error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
      options: { captchaToken },
    });
    if (!error) {
      sessionStorage.setItem("active", true);
      checkUser();
      if (!stashedUser) {
        navigate("/upload", { state: { closeDrawer: true } });
      }
    }
    return error;
  };

  const signUpWithEmail = async (email, password, captchaToken) => {
    const { data, error } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: { captchaToken },
    });
    if (
      data?.user &&
      data?.user?.identities &&
      data?.user?.identities?.length === 0
    ) {
      return {
        name: "EmailInUseError",
        message: "User already exists",
      };
    }
    if (!error) {
      sessionStorage.setItem("active", true);
    }

    return error;
  };

  const forgotPassword = async (email, captchaToken) => {
    const { error } = await supabase.auth.signInWithOtp({
      email: email,
      options: { captchaToken },
    });
    if (!error) {
      localStorage.setItem("forgotPassword", true);
    }
    return error;
  };

  const changePassword = async (email, password) => {
    const { error } = await supabase.auth.updateUser({
      email: email,
      password: password,
    });
    return error;
  };

  const logout = async () => {
    await supabase.auth.signOut();
    setShowPopup(false);
  };

  const downloadImage = async (id, path) => {
    const { data, error } = await supabase.storage
      .from("images")
      .download(`${id}/${path}`);
    if (!error) {
      return data;
    }
  };

  const getSubscriptions = async () => {
    if (user) {
      const { data: subscriptionData, error } = await supabase
        .from("subscriptions")
        .select("*")
        .match({ user_id: user?.id, active: true })
        .order("created_at", { ascending: true });
      if (!error) {
        return subscriptionData;
      }
      return null;
    }
  };

  const handlePayment = async (
    price_id,
    credits_count,
    multiple,
    coupon_id = undefined
  ) => {
    try {
      if (!user && !stashedUser) {
        throw new Error("User not found");
      }

      if (
        !price_id ||
        !credits_count ||
        !multiple ||
        credits_count > 100000 ||
        multiple > 99
      ) {
        throw new Error("Invalid parameters");
      }

      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/stripe/checkout-session`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            customerEmail: user?.email || stashedUser?.email,
            priceId: price_id,
            quantity: multiple,
            creditAmount: credits_count,
            cancelPage: window.location.pathname,
            couponId: coupon_id,
            rewardfulId: window?.Rewardful?.referral || undefined,
          }),
        }
      );

      const { data, error } = await response.json();

      if (!error && data?.url) {
        window.location.href = data.url;
      }
    } catch (e) {}
  };

  const loadUserFromStash = async (customPath = undefined) => {
    sessionStorage.setItem("active", true);
    checkUser(customPath);
  };

  function timestampToTimestampzUTC(timestamp) {
    const date = new Date(timestamp * 1000);
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const milliseconds = date.getUTCMilliseconds();
    const timestampzUTC = `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")} ${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}.${milliseconds
      .toString()
      .padStart(3, "0")} UTC`;
    return timestampzUTC;
  }

  function getCurrentPeriod() {
    const currentDate = new Date();
    const period_start = new Date(
      Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth(),
        1,
        0,
        0,
        0
      )
    ).getTime();
    const period_end = new Date(
      Date.UTC(
        currentDate.getUTCFullYear(),
        currentDate.getUTCMonth() + 1,
        0,
        23,
        59,
        59
      )
    ).getTime();
    return { period_start, period_end };
  }

  const getPlanCreditsUsed = (generations) => {
    let credits = 0;
    generations?.forEach((gen) => {
      if (gen?.bundle_used !== true) {
        credits += gen?.credits_used;
      }
    });
    return credits;
  };

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event) => {
      switch (event) {
        case "SIGNED_OUT":
          setUser(null);
          setProfitWellStarted(false);
          navigate("/", { state: { closeDrawer: true } });
          break;
        default:
      }
    });

    checkUser();

    return () => {
      subscription?.unsubscribe();
    };
  }, [navigate]);

  useEffect(() => {
    const getBundleCredits = async (userId) => {
      const { data, error } = await supabase
        .from("bundles")
        .select("*")
        .match({ user_id: userId });
      if (!error) {
        let bundleUsed = 0;
        let bundleAllowed = 0;
        let bundleAllowedTotal = 0;
        if (data?.length !== 0) {
          for (let i = 0; i < data?.length; i++) {
            if (data[i]?.active) {
              bundleUsed += data[i]?.amount_used;
              bundleAllowed += data[i]?.bundle?.credits;
            }
            bundleAllowedTotal += data[i]?.bundle?.credits;
          }
        }
        setBundleCreditsUsed(bundleUsed);
        setBundleCreditsAllowed(bundleAllowed);
        setBundleCreditsAllowedTotal(bundleAllowedTotal);
      }
    };
    const getGenerations = async (subId, periodStart, periodEnd) => {
      if (user) {
        let ps,
          pe = null;
        if (!periodStart || !periodEnd) {
          const { period_start, period_end } = getCurrentPeriod();
          ps = period_start;
          pe = period_end;
        }
        const { data: generations, error } = await supabase
          .from("generations")
          .select("*")
          .match({
            user_id: user?.id,
            sub_id: subId,
          })
          .gt(
            "created_at",
            ps ? timestampToTimestampzUTC(ps / 1000) : periodStart
          )
          .lt(
            "created_at",
            pe ? timestampToTimestampzUTC(pe / 1000) : periodEnd
          )
          .limit(1000000);

        if (!error) {
          setPlanCreditsUsed(getPlanCreditsUsed(generations) || 0);
        }
      }
    };

    const checkCredits = async () => {
      if (user) {
        await getBundleCredits(user?.id);
        const subscriptions = await getSubscriptions();
        if (subscriptions?.[subscriptions?.length - 1]?.active) {
          await getGenerations(
            subscriptions?.[subscriptions?.length - 1]?.id,
            subscriptions?.[subscriptions?.length - 1]?.period_start || null,
            subscriptions?.[subscriptions?.length - 1]?.period_end || null
          );
        }
      }
    };
    checkCredits();
    setSubChanged(false);
  }, [user, subChanged]);

  useEffect(() => {
    const checkPopup = async () => {
      let planCreditsAllowed = 0;
      const subscriptions = await getSubscriptions();
      if (subscriptions?.[subscriptions?.length - 1]?.active) {
        planCreditsAllowed =
          subscriptions?.[subscriptions?.length - 1]?.plan?.metadata?.credits;
      }
      if (
        bundleCreditsAllowed !== undefined &&
        bundleCreditsUsed !== undefined
      ) {
        if (
          planCreditsAllowed - (planCreditsUsed || 0) <= 0 &&
          bundleCreditsAllowed - bundleCreditsUsed <= 0 &&
          window.location.pathname !== "/thanks"
        ) {
          setShowPopup(true);
        } else {
          setShowPopup(false);
        }
        if (planCreditsUsed >= planCreditsAllowed) {
          setPlanEmpty(true);
        } else {
          setPlanEmpty(false);
        }
      }
    };
    if (user) {
      checkPopup();
    }
  }, [user, bundleCreditsAllowed, bundleCreditsUsed, planCreditsUsed]);

  useEffect(() => {
    if (user !== null) {
      if (localStorage.getItem("goToCheckout")) {
        const { price_id, credits_count, multiple, coupon_id } = JSON.parse(
          localStorage.getItem("goToCheckout")
        );
        handlePayment(price_id, credits_count, multiple, coupon_id);
      } else if (localStorage.getItem("openCheckout")) {
        setOpenWindow("more-photos");
      } else if (localStorage.getItem("forgotPassword")) {
        setOpenWindow("my-account");
      }
      localStorage.removeItem("goToCheckout");
      localStorage.removeItem("openCheckout");
      localStorage.removeItem("forgotPassword");
    }
    if (checkStorageFromTrends) {
      setCheckStorageFromTrends(false);
    }
  }, [user, checkStorageFromTrends]);

  useEffect(() => {
    if (
      user?.email &&
      blockedTerms.some((term) => user?.email?.split("@")?.[1]?.includes(term))
    ) {
      logout();
    }
  }, [user]);

  useEffect(() => {
    const currency = localStorage.getItem("currency");
    if (currency) {
      setCurrentCurrency(currency);
    } else {
      const browserLanguage = navigator.language || navigator.userLanguage;
      if (
        browserLanguage &&
        countryCodesEU.some((code) => browserLanguage.startsWith(code))
      ) {
        setCurrentCurrency("EUR");
      }
    }
  }, []);

  return (
    <SessionContext.Provider
      value={{
        login,
        loginWithEmail,
        signUpWithEmail,
        forgotPassword,
        changePassword,
        logout,
        user,
        downloadImage,
        subStatus,
        planCreditsUsed,
        setPlanCreditsUsed,
        bundleCreditsAllowed,
        bundleCreditsUsed,
        setBundleCreditsUsed,
        planEmpty,
        setPlanEmpty,
        showPopup,
        setSubChanged,
        profitWellStarted,
        setProfitWellStarted,
        setCheckStorageFromTrends,
        openWindow,
        setOpenWindow,
        stashedUser,
        loadUserFromStash,
        currentCurrency,
        setCurrentCurrency,
        handlePayment,
        maxBatchSize,
        maxImageSize,
        maxVideoSize,
        enableExport,
        bundleCreditsAllowedTotal,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};

export default SessionContextProvider;
