import { useContext } from "react";
import { Button } from "@mui/material";
import { SessionContext } from "../context/SessionContext";

const CurrencySelect = ({ color }) => {
  const session = useContext(SessionContext);

  return (
    <div style={{ display: "flex", padding: "1rem" }}>
      <Button
        variant={session?.currentCurrency === "USD" ? "contained" : "outlined"}
        onClick={() => {
          session?.setCurrentCurrency("USD");
          localStorage.setItem("currency", "USD");
        }}
        style={{
          marginRight: 8,
          opacity: session?.currentCurrency === "USD" ? 1 : 0.5,
          backgroundColor: session?.currentCurrency === "USD" ? color : "",
          borderColor: session?.currentCurrency === "USD" ? color : "",
          color: session?.currentCurrency === "USD" ? "white" : "",
          fontWeight: "bold",
        }}
      >
        USD
      </Button>
      <Button
        variant={session?.currentCurrency === "EUR" ? "contained" : "outlined"}
        onClick={() => {
          session?.setCurrentCurrency("EUR");
          localStorage.setItem("currency", "EUR");
        }}
        style={{
          opacity: session?.currentCurrency === "EUR" ? 1 : 0.5,
          backgroundColor: session?.currentCurrency === "EUR" ? color : "",
          borderColor: session?.currentCurrency === "EUR" ? color : "",
          color: session?.currentCurrency === "EUR" ? "white" : "",
          fontWeight: "bold",
        }}
      >
        EUR
      </Button>
    </div>
  );
};

export default CurrencySelect;
