import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import PricingContainer from "./pricingContainer";

function LandingPricing() {
  const location = useLocation();
  const { section } = useParams();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    const handlePageLoad = () => {
      setIsPageLoaded(true);
    };

    if (document.readyState === "complete") {
      setIsPageLoaded(true);
    } else {
      window.addEventListener("load", handlePageLoad);
    }

    return () => {
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  const handleScrollToPricing = () => {
    const element = document.getElementById("pricing");
    element.scrollIntoView();
    window.scrollBy(0, -50);
  };

  useEffect(() => {
    if (isPageLoaded && location.state?.scrollToPricing) {
      handleScrollToPricing();
    }
  }, [location.state, isPageLoaded]);

  useEffect(() => {
    if (isPageLoaded && section === "pricing") {
      handleScrollToPricing();
    }
  }, [section, isPageLoaded]);

  return (
    <Box
      sx={{
        backgroundRepeat: "repeat",
        borderTop: "1px solid #dcdcdc",
        backgroundColor: "white",
        paddingTop: "1rem",
      }}
      id={"pricing"}
    >
      <PricingContainer />
    </Box>
  );
}

export default LandingPricing;
