import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { useTranslation } from "react-i18next";

function LandingFooter() {
  const { t } = useTranslation();

  const footerTools = [
    { name: t("landing.uploadFooter"), href: "/upload" },
    { name: t("landing.apiFooter"), href: "/api" },
    {
      name: t("landing.pluginFooter"),
      href: "/lightroom-plugin",
    },
    { name: t("landing.trendsFooter"), href: "/trends" },
    {
      name: t("landing.eCommFooter"),
      href: "/ecommerce-ai-image-search",
    },
    {
      name: t("landing.metadataFooter"),
      href: "/iptc-metadata-for-jpeg-images",
    },
    {
      name: t("landing.vectorFooter"),
      href: "/ai-vector-svg-keywords-and-captions",
    },
    {
      name: t("landing.videoFooter"),
      href: "/generate-ai-keywords-mp4-mov-avi",
    },
  ];

  const legalLinks = [
    { name: t("landing.privacy"), href: "/privacy" },
    { name: t("landing.service"), href: "/tos" },
    { name: t("landing.use"), href: "/aup" },
    { name: t("landing.refund"), href: "/refund-policy" },
  ];

  const footerLinks = [
    { name: t("landing.pricing"), href: "/pricing" },
    { name: t("landing.faqs"), href: "/faq" },
    { name: t("landing.blog"), href: "/blog" },
    {
      name: t("landing.affiliate"),
      href: "/affiliates",
    },
    {
      name: t("landing.camera"),
      href: "https://www.flaticon.com/free-icons/camera",
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#dcdcdc",
        borderTop: "1px solid #dcdcdc",
        paddingBottom: { xs: "8rem", md: "3rem" },
        paddingTop: "3rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "space-between",
          maxWidth: "1200px",
          margin: "0 auto",
          px: 3,
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Box sx={{ maxWidth: "400px", mb: { xs: 3, md: 0 } }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
          >
            PhotoTag.ai, from{" "}
            <Link
              href="https://www.codeconda.com"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "#FF0000" }}
            >
              CodeConda
            </Link>
          </Typography>
          <Typography variant="body2" sx={{ color: "#333", mb: 2 }}>
            {t("landing.footerText")}
          </Typography>
          <Typography variant="body2" sx={{ color: "#333", mb: 2 }}>
            {t("landing.footerDeveloped")}
          </Typography>
          <Typography variant="body2" sx={{ color: "#333", mb: 2 }}>
            {t("landing.footerContact")}{" "}
            <Link
              href="mailto:info@codeconda.com"
              target="_blank"
              rel="noopener noreferrer"
              sx={{ color: "#FF0000" }}
            >
              info@codeconda.com
            </Link>
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: { xs: 3, md: 0 },
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
          >
            {t("landing.footerTools")}
          </Typography>
          {footerTools.map((tool, index) => (
            <Link
              key={index}
              href={tool.href}
              underline="hover"
              sx={{ color: "#333", mb: 1 }}
            >
              <Typography variant="body2">{tool.name}</Typography>
            </Link>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: { xs: 3, md: 0 },
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
          >
            {t("landing.footerLegal")}
          </Typography>
          {legalLinks.map((link, index) => (
            <Link
              key={index}
              href={link.href}
              underline="hover"
              sx={{ color: "#333", mb: 1 }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="body2">{link.name}</Typography>
            </Link>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            mb: { xs: 3, md: 0 },
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", color: "#333", mb: 2 }}
          >
            {t("landing.footerLinks")}
          </Typography>
          {footerLinks.map((link, index) => (
            <Link
              key={index}
              href={link.href}
              underline="hover"
              sx={{ color: "#333", mb: 1 }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography variant="body2">{link.name}</Typography>
            </Link>
          ))}
        </Box>
      </Box>
      <Typography
        variant="body2"
        sx={{ color: "#333", textAlign: "center", mt: 5 }}
      >
        {t("landing.footerRights")}
      </Typography>
    </Box>
  );
}

export default LandingFooter;
