import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useContext } from "react";
import { StripeCustom } from "./StripePlans";
import { SessionContext } from "../context/SessionContext";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import PricingContainer from "./pricingContainer";

const Plans = ({ blocked = false, customID = undefined }) => {
  const { t } = useTranslation();
  const session = useContext(SessionContext);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {!customID && (
        <>
          <Typography
            variant="h4"
            color="initial"
            fontWeight="bold"
            align="center"
            sx={{
              width: "80%",
              padding: { xs: "0 2rem" },
            }}
          >
            {blocked ? t("plan.blocked") : t("plan.purchase")}
          </Typography>
          <Typography
            variant="p"
            color="initial"
            align="center"
            sx={{
              width: "80%",
              padding: { xs: "0 2rem" },
              marginTop: "1rem",
            }}
          >
            {t("plan.discount", {
              discount: process.env.REACT_APP_STRIPE_DEFAULT_PROMO_PERCENT,
            })}
          </Typography>
        </>
      )}
      <Box
        sx={{
          width: { xs: "90%", md: "100%" },
          minWidth: { xs: "15rem", md: "40rem" },
        }}
      >
        {session?.user?.id ? (
          customID ? (
            <StripeCustom userID={session?.user?.id} customID={customID} />
          ) : (
            <PricingContainer landingPage={false} />
          )
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              minHeight: "20rem",
              marginTop: "5rem",
            }}
          >
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Plans;
